import React, { ComponentType, Suspense, lazy } from 'react'

export const createLazyComponent = <T extends ComponentType<any>>(
  promise: Promise<{ default: T }>,
  fallback: React.ReactNode = ''
) => {
  const LazyComponent = lazy(() => promise)

  const WrappedComponent = (props: React.ComponentProps<T>) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  )

  WrappedComponent.displayName = `Lazy(${LazyComponent.name})`
  return WrappedComponent
}
