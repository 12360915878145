import { createLazyComponent } from './utils/createLazyComponent'

export default createLazyComponent(
  import('./RootComponent').then((module) => ({
    default: module.RootComponent,
  }))
)

export const DeactivateUserDialog = createLazyComponent(
  import('./components/Dialogs/DeactivateUserDialog').then((module) => ({
    default: module.DeactivateUserDialog,
  }))
)

export const DeleteUserDialog = createLazyComponent(
  import('./components/Dialogs/DeleteUserDialog').then((module) => ({
    default: module.DeleteUserDialog,
  }))
)

export const ReactivateUserDialog = createLazyComponent(
  import('./components/Dialogs/ReactivateUserDialog').then((module) => ({
    default: module.ReactivateUserDialog,
  }))
)

export const NewUserSimpleDialog = createLazyComponent(
  import('./components/Dialogs/components/NewUserSimpleDialog').then((module) => ({
    default: module.NewUserSimpleDialog,
  }))
)

export const UserComponent = createLazyComponent(
  import('./components/UserComponent').then((module) => ({
    default: module.UserComponent,
  }))
)
